
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import InputUpload from "@/shared/components/Forms/InputUpload.vue";
import LayoutEmptyPage from "@/shared/components/Layouts/LayoutEmptyPage.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ModalPrompt from "@/shared/components/ModalPrompt.vue";
import ResultCard from "@/shared/components/ResultCard.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { useCart } from "@/shared/composables/useCart";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { useLatestQuotation } from "@/shared/composables/useLatestQuotation";
import {
  analyticsTrackCartCheckout,
  analyticsTrackCartClear,
  analyticsTrackCartRemove,
} from "@/shared/utils/analytics";
import { config } from "@/shared/utils/config";
import { formatDurationFromSeconds } from "@/shared/utils/date";
import message from "@/shared/utils/message";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import routeNames from "@/web/router/routeNames";
import type { UploadFile } from "ant-design-vue";
import isEmpty from "lodash/isEmpty";
import { computed, defineComponent, reactive, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { PlusOutlined } from "@ant-design/icons-vue";
import { EditOutlined } from "@ant-design/icons-vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { videoLengthTextFormat } from "@/shared/utils/video";
import ReuseQuotationModal from "@/shared/components/ReuseQuotationModal.vue";
import { scrollToTop } from "@/shared/utils/browser";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { redirectToKyc, redirectToVideoDetails, redirectToQuote } =
      useRedirectHelper();
    const { openGlobalModal: openReuseQuotationModal } =
      useGlobalModal("REUSE_QUOTATION");
    const { openGlobalModal: openAskForQuotationModal } =
      useGlobalModal("ASK_FOR_QUOTATION");

    const {
      cartNotes,
      cartList,
      cartListLoading,
      handleRemoveFromCart,
      removeFromCartLoading,
      handleCheckOutOrder,
      checkOutOrderLoading,
      updateCartNotes,
    } = useCart();
    const { currentSubscriptionPlan, currentSubscriptionPlanLoading } =
      useSubscriptionPlans();
    const { shouldAskForQuotation, latestQuotationHasOrder } =
      useLatestQuotation();
    const { kycDone, fetchBusinessProfile, businessProfileLoading } =
      useBusinessProfile();

    const subscriptionMaxItem = ref(4);

    const handleClearCart = async () => {
      console.log("handleClearCart");

      analyticsTrackCartClear(cartList.value);

      try {
        /**
         * Calling remove from cart with null video id clears the cart
         */
        await handleRemoveFromCart({ cartItemId: null });

        setTimeout(() => {
          scrollToTop();
        }, 50);
      } catch (e) {
        console.log(e);
      }
    };

    const handleRemoveItem = async (cartItemId: string, videoTitle = "") => {
      console.log("handleRemoveItem", cartItemId);

      analyticsTrackCartRemove(cartItemId, videoTitle);

      try {
        await handleRemoveFromCart({ cartItemId });
      } catch (e) {
        console.log(e);
      }
    };

    const handleCheckout = async () => {
      console.log("handleCheckout", formState);

      /** 1. If user's latest quotation has an order, ask if he wants to reuse it
       * 2. Else if user has not yet submitted his/her altest quotation, ask  if
       * he/she wants to subscribe to a plan if not yet subscribed */
      if (latestQuotationHasOrder.value) {
        return openReuseQuotationModal();
      } else if (shouldAskForQuotation.value) {
        return openAskForQuotationModal();
      }

      // If KYC is not yet done, ask for KYC
      await fetchBusinessProfile();
      if (!kycDone.value) {
        redirectToKyc({ isCheckingOut: true });
        return;
      }

      analyticsTrackCartCheckout(cartList.value, kycDone.value);

      try {
        // Proceed to Checkout
        await handleCheckOutOrder(
          { notes: formState.notes },
          formState.moodboardFileList[0]?.originFileObj
        );

        // Notify of successful checkout
        message["success"](t("Successfully checked out your cart."));

        router.push({
          name: routeNames.thankYou,
        });
      } catch (e) {
        console.log(e);
      }
    };

    const handleCartItemSelect = (cartItemId: string, videoIdeaId: string) => {
      redirectToVideoDetails({ videoId: videoIdeaId, cartItemId: cartItemId });
    };

    // Cart Notes
    const formState = reactive({
      notes: "",
      moodboardFileList: [] as UploadFile[],
    });

    const handleCartNotesBlur = async (notes: string) => {
      console.log("Save cart notes: " + notes);

      // Save cart notes
      try {
        await updateCartNotes({ notes: formState.notes });
      } catch (error) {
        console.error(error);
      }
    };

    const redirectToHome = () => {
      router.push({ name: routeNames.home });
    };

    watchEffect(() => {
      // Load saved cart notes
      if (cartNotes.value) {
        formState.notes = cartNotes.value;
      }

      // Load current subscription plan number of videos
      if (currentSubscriptionPlan.value) {
        subscriptionMaxItem.value = currentSubscriptionPlan.value.maxVideoCount;
      }
    });

    return {
      t,
      config,
      formatDurationFromSeconds,
      cartNotes,
      cartList,
      cartListLoading,
      removeFromCartLoading,
      checkOutOrderLoading: computed(
        () => checkOutOrderLoading.value || businessProfileLoading.value
      ),
      cartEmpty: computed(() => isEmpty(cartList.value)),
      formState,
      handleClearCart,
      handleCheckout,
      handleRemoveItem,
      handleCartItemSelect,
      handleCartNotesBlur,
      currentSubscriptionPlan,
      currentSubscriptionPlanLoading,
      subscriptionMaxItem,
      showCartCountWarning: computed(
        () => cartList.value.length < subscriptionMaxItem.value
      ),
      routeNames,
      redirectToHome,
      redirectToQuote,
      videoLengthTextFormat,
    };
  },
  methods: { makeRequiredRule },
  components: {
    PageLayout,
    ResultCard,
    FormGroup,
    InputTextArea,
    InputUpload,
    ModalPrompt,
    LayoutEmptyPage,
    PlusOutlined,
    EditOutlined,
    ReuseQuotationModal,
  },
});
